import { render, staticRenderFns } from "./IndexList.vue?vue&type=template&id=1fd047e0&"
import script from "./IndexList.vue?vue&type=script&lang=ts&"
export * from "./IndexList.vue?vue&type=script&lang=ts&"
import style0 from "@/assets/css/resource-list.scss?vue&type=style&index=0&lang=scss&"
import style1 from "./IndexList.vue?vue&type=style&index=1&module=true&lang=scss&"




function injectStyles (context) {
  
  this["$style"] = (style1.locals || style1)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ResourceItem: require('/var/static/components/ResourceItem.vue').default})
