























































































import Vue from "vue";
import ResourceItem from "@/components/ResourceItem.vue";
import { joinUrlQueryString } from "@/utils/util";
import { IFeedItem, IZoneResourceItem } from "@/types/post";
import feedMixin from "@/mixins/feed";
import resizeMixin from "@/mixins/resize";

export default Vue.extend({
  name: "index-list",
  mixins: [feedMixin, resizeMixin],
  components: {
    ResourceItem,
  },
  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
    resource: {
      type: Object,
      default: () => ({}),
    },
    index: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      lastReportIndex: 0,
      shownCardData: [],
      position: 0,
      arrowLeftVisible: false,
      arrowRightVisible: false,
      typeList: ["科技海报", "活动banner", "蓝色主题", "五花八门"],
      ratioMap: {
        0: 254,
        1: 254,
        2: 190,
        3: 254,
      },
      ratioTypeMap: {
        0: "default",
        1: "long",
        2: "high",
        3: "square",
      },
    };
  },
  computed: {
    queryStr() {
      const queryObj = this.$route.query;
      const routeName = this.$route.name;
      const queryStr = joinUrlQueryString(queryObj, routeName);
      return queryStr;
    },
    isPcClient() {
      if (process.client) {
        return !!window.native;
      }
      return this.$store.state.isPCNative;
    },
    itemLen() {
      if (this.resource && this.resource.data) {
        return this.resource.data.length;
      }
      return 0;
    },
  },
  watch: {
    shownCardData(newData, oldData) {
      setTimeout(() => {
        this.reportShowStat();
      }, 1000);
    },
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    stat(action: string, data: any) {
      this.$stat("xiaozhan", action, data);
    },
    onTitleVisible(visible: boolean) {
      if (visible) {
        setTimeout(() => {
          this.stat("xiaozhan_zone_module_show", {
            zone_name: encodeURIComponent(this.info.name),
            zone_position: this.index,
            ratio: this.ratioTypeMap[this.info.ratio || "0"],
          });
        }, 1000);
      }
    },
    reportClickStat(clickid: string) {
      this.stat("xiaozhan_zone_module_click", {
        zone_name: encodeURIComponent(this.info.name),
        zone_position: this.index,
        clickid,
        ratio: this.ratioTypeMap[this.info.ratio || "0"],
      });
    },
    handleCardVisible(
      item: IZoneResourceItem,
      index: number,
      visible: boolean
    ) {
      if (visible) {
        const rawData = [...this.shownCardData];
        if (!rawData[index]) {
          rawData[index] = item;
          this.shownCardData = rawData;
        }
      }
    },
    reportShowStat() {
      const len = this.shownCardData.length;
      const lastIndex = this.lastReportIndex;
      const unit = 4;

      for (let i = lastIndex; i < len; i = i + unit) {
        const chunk = this.shownCardData
          .slice(i, i + unit)
          .map((c: any): IFeedItem => ({ ...c, title: c.res_name }));
        this.genFeedStatData(chunk, this.page, this.info.name, "", lastIndex);
      }
      this.lastReportIndex = len;
    },
    handleLeftClick() {
      const sliderElem = this.$refs.sliderElem;
      if (sliderElem) {
        let itemWidth = this.ratioMap[this.ratio || 0];
        if (sliderElem.children && sliderElem.children[0]) {
          itemWidth = sliderElem.children[0].getBoundingClientRect().width;
        }

        const gap = 16;
        const lastPosition = this.position;
        let currentPosition = lastPosition - 4 * itemWidth - 4 * gap;
        const minTranslate = 0;
        if (currentPosition <= minTranslate) {
          currentPosition = minTranslate;
          this.arrowLeftVisible = false;
        }

        this.position = currentPosition;
        this.arrowRightVisible = true;

        sliderElem.style.transform = `translateX(-${currentPosition}px)`;
      }
    },
    handleRightClick() {
      const sliderElem = this.$refs.sliderElem;
      if (sliderElem) {
        const containerWidth = sliderElem.getBoundingClientRect().width || 1000;
        const children = sliderElem.children;
        const itemLen = children.length;
        let itemWidth = this.ratioMap[this.ratio || 0];
        if (sliderElem.children && sliderElem.children[0]) {
          itemWidth = sliderElem.children[0].getBoundingClientRect().width;
        }
        const gap = 16;
        const listWidth = itemLen * itemWidth + itemLen * gap - gap;
        const lastPosition = this.position;
        let currentPosition = lastPosition + 4 * itemWidth + 4 * gap;
        const maxTranslate = listWidth - containerWidth;
        if (currentPosition >= maxTranslate) {
          currentPosition = maxTranslate;
          this.arrowRightVisible = false;
        }

        this.position = currentPosition;
        this.arrowLeftVisible = true;

        sliderElem.style.transform = `translateX(-${currentPosition}px)`;
      }
    },
  },
});
