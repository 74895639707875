export default {
  data() {
    return {
      resizeObserver: null,
      requestAnimationId: null,
      arrowLeftVisible: false,
      arrowRightVisible: false,
      position: 0,
    };
  },
  computed: {},
  mounted() {
    const sliderElem = this.$refs.sliderElem;
    if (window.ResizeObserver && sliderElem) {
      this.resizeObserver = new ResizeObserver((entries) => {
        // avoid ResizeObserver loop limit exceeded warning
        this.requestAnimationId = window.requestAnimationFrame(() => {
          if (!Array.isArray(entries) || !entries.length) {
            return;
          }

          for (let entry of entries) {
            if (entry.target.nodeName.toLowerCase() === "div") {
              this.handleResize(200);
            }
          }
        });
      });

      if (sliderElem.parentNode) {
        this.resizeObserver.observe(sliderElem.parentNode);
      }
    } else {
      this.arrowRightVisible = true;
    }

    this.handleResize(200);
  },
  beforeDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }

    if (this.requestAnimationId && window.cancelAnimationFrame) {
      window.cancelAnimationFrame(this.requestAnimationId);
    }
  },
  methods: {
    stat(action: string, data: any): void {
      this.$stat("xiaozhan", action, data);
    },
    handleResize(width: number) {
      const sliderElem = this.$refs.sliderElem;
      if (sliderElem) {
        sliderElem.style.transform = "translateX(0)";
        this.arrowLeftVisible = false;
        this.position = 0;

        const containerWidth = sliderElem.getBoundingClientRect().width || 1000;
        let itemWidth = width;
        let len = 0;
        if (sliderElem.children && sliderElem.children[0]) {
          itemWidth = sliderElem.children[0].getBoundingClientRect().width;
          len = sliderElem.children.length;
        }

        if (itemWidth * len > containerWidth) {
          this.arrowRightVisible = true;
        } else {
          this.arrowRightVisible = false;
        }
      }
    },
  },
};
